<template>
  <div style="display: inline">
    <button
      v-if="isActiveProductos"
      class="mr-2"
      :class="activateButton ? 'btn btn-success' : 'btn btn-secondary'"
      @click="showModalEvent"
      :disabled="havePreCierre"
      :title="havePreCierre ? messagePreCierre : ''"
      type="button"
    >
      {{nameButton}}
    </button>
    <b-modal v-model="showModal"
    size="lg"
    :title="nameButton"
    hide-footer
    @hide="onCloseModal" >
      <div class="row">
        <div class="col-sm-7">
          <div class="form-group row">
            <div class="col-sm-12">
                <label for="moneda_id"
                class="col-form-label text-right" >Productos:</label >
            </div>
            <div class="col-sm-12">
              <multiselect2
                v-model="salidaProducto.producto"
                track-by="nombre" label="nombre"
                :loading="load_multiselect_product"
                :internal-search="false"
                placeholder="Seleccione Producto"
                :options="productosStatic"
                :allow-empty="false"
                :show-labels="false"
                :custom-label="customLabelProduct"
                @search-change="busquedaAsincronaProductos"
                @select="getDataProducto"
                :disabled="isDisabledInputMedicamentoErp">
                <span slot="caret" v-if="!(salidaProducto.producto===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.nombre }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.codigo }} - {{ props.option.nombre }} -
                  <span style="color: red; font-weight: bold">
                    {{ props.option.sub_grupo }}
                  </span>
                </template>
                <template slot="afterList">
                    <div class="row">
                        <a
                        v-if="showCargarMas"
                        href="javascript:void(0)"
                        class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
                        @click="getListProduct(null)">
                        Cargar mas...
                        </a>
                    </div>
                </template>
              </multiselect2>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group row">
            <div class="col-sm-12">
                <label for="moneda_id"
                class="col-form-label text-right" >Unidad:</label >
            </div>
            <div class="row col-sm-12">
              <div class="col-sm-5">
                <label class="radio radio-success">
                    <input type="radio" v-model="salidaProducto.unidad" value="10" ref="unidad">
                    <span>
                      {{ (salidaProducto.producto && salidaProducto.producto.unidad_medida )?
                        salidaProducto.producto.unidad_medida.descripcion : '' }}
                    </span>
                    <span class="checkmark"></span>
                  </label>
                    <!-- <template
                      v-if="salidaProducto.producto
                        && salidaProducto.producto.unidad_medida_alternativa">
                        <label class="radio radio-primary">
                          <input type="radio" value="10" ref="unidad2">
                          <span>
                            {{ salidaProducto.producto.unidad_medida_alternativa.descripcion }}
                          </span>
                          <span class="checkmark"></span>
                        </label>
                        <strong class="row">
                          Conv.: {{salidaProducto.producto.conversiones}}</strong>
                    </template> -->
              </div>
              <div class="col-sm-7">
                <span style="line-height: inherit" class="badge badge-pill ml-4"
                :class="{'badge-success': salidaProducto.stock && salidaProducto.stock.saldo_up !== 0, 'badge-danger': salidaProducto.stock && salidaProducto.stock.saldo_up === 0}"
                >
                  <!-- salidaProducto.stock.saldo_up -->
                  Stock: {{salidaProducto.stock ? salidaProducto.stock.saldo_up : 0}}
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group row">
            <div class="col-sm-12">
                <label for="cantidad"
                class="col-form-label text-right" >Cantidad:</label>
            </div>
            <div class="col-sm-9">
              <input type="text"
                v-model.number="salidaProducto.cantidad"
                @keypress="onlyNumber" @paste.prevent
                class="form-control">
              </div>
          </div>
        </div>
        <div class="col-sm-4" v-if="canAgregarProducto">
          <div class="form-group row">
            <div class="col-sm-12">
              <label for="manual"
                class="col-form-label text-right" >Crear Otros Medicamentos:</label >
            </div>
            <div class="col-sm-12">
              <vue-tags-input
                v-model="medicamentoModel"
                :tags="tagsMedicamentos"
                :autocomplete-items="filteredItemsMedicamentos"
                :max-tags="1"
                @tags-changed="validateTagMedicamentosBeforeSaving"
                :separators="['§§§']"
                class="tag-custom text-15"
                placeholder=""
                :disabled="isDisabledInputMedicamentoClinic"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group row">
            <div class="col-sm-12">
              <label for="indicaciones"
                class="col-form-label text-right" >Indicaciones:</label >
            </div>
            <div class="col-sm-12">
              <vue-tags-input
                v-model="indicacionModel"
                :tags="tagsIndicacion"
                :autocomplete-items="filteredItemsIndicaciones"
                :max-tags="1"
                @tags-changed="validateTagIndicationBeforeSaving"
                :separators="['§§§']"
                class="tag-custom text-15"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group row">
            <div class="col-sm-12">
              <label for="via" class="col-form-label text-left">Via</label>
            </div>
            <div class="col-sm-10">
              <multiselect2
                v-model="tipoViaAdministracionSelected"
                track-by="id"
                label="nombre"
                placeholder="Seleccione"
                :options="tipoViaAdministracions"
                :allow-empty="false">
                </multiselect2>
            </div>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="form-group row">
            <div class="col-sm-12">
                <div class="col-sm-12">
                  <label for="moneda_id"
                    class="col-form-label text-right" >Glosa:</label >
                </div>
                <div class="col-sm-12">
                  <b-form-textarea
                    id="glosa"
                    class="form-control no-resize"
                    v-model="salidaProducto.glosa"
                    placeholder="Ingrese alguna descripcion."
                    rows="3"
                    max-rows="4"
                    style="overflow-y: auto;"/>
                </div>
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="form-group row mt-4">
            <template v-if="sugerencia_glosas.length > 0">
              <div class="col-sm-4 mt-2 pl-0" v-for="(gloss, index) in sugerencia_glosas"
                v-bind:item="gloss"
                v-bind:index="index"
                v-bind:key="index">
                <span @click="agregarTextoAGlosa(gloss)"
                  style="cursor: pointer; line-height: inherit"
                  class="badge badge-pill badge-primary ml-2 p-1">
                    {{ gloss.nombre }}
                </span>
              </div>
            </template>
          </div>
        </div>
          <div class="col-sm-12 text-right pr-4">
            <button
              type="button"
              class="btn btnBlue mr-2"
              :disabled="load_multiselect_product"
              @click="saveProductos(3)"
              v-if="hasActiveEvolucionMedica">
              Receta
            </button>
            <button
              type="button"
              class="btn btnOrange mr-2"
              :disabled="load_multiselect_product || isButtonDisabled || isButtonDisabledStock"
              @click="saveProductos(1)">
              Insumo
            </button>
            <button
              type="button"
              class="btn btnTurquesa"
              :disabled="load_multiselect_product || isButtonDisabled || isButtonDisabledStock"
              @click="saveProductos(2)">
              Venta
            </button>
          </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect2 from '@/components/util/vue-multiselect2';
import { mapState } from 'vuex';
import axios from '@/modules/common/axiosERP';
import moment from 'moment';
import { mapFields } from 'vuex-map-fields';
import { util } from '../../../plugins/util';

export default {
  name: 'TheModalConsumoProductos',
  mounted() {
    this.$parent.$on('delDetalle', this.delDetalle);
  },
  props: {
    almacen: {
      type: Object,
      default: null,
    },
    moneda: {
      type: Object,
      default: null,
    },
    sugerencia_glosas: {
      type: Array,
      default: Array,
    },
    fecha: null,
    detalles: {
      type: Array,
      default: Array,
    },
    havePreCierre: {
      type: Boolean,
      required: true,
      default: false,
    },
    activateButton: {
      type: Boolean,
      required: true,
      default: false,
    },
    isActiveProductos: {
      type: [Number, Boolean],
      default: false,
    },
    nameButton: {
      type: String,
      required: true,
    },
    hasActiveEvolucionMedica: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      productosStatic: [],
      salidaProducto: {
        producto: null,
        unidad: true,
        cantidad: 1,
        glosa: '',
        stock: { saldo_ua: 0, saldo_up: 0 },
        stockLotes: [],
        usuario: '',
        fecha: '',
      },
      detalle: {},
      stockLotes: [],
      load_multiselect_product: false,
      filter: '',
      totalRegistro: '',
      messagePreCierre: 'Acción no permita usted tiene un precierre',
      indicacionModel: '',
      tagsIndicacion: [],
      indicacionesList: [],
      medicamentoModel: '',
      tagsMedicamentos: [],
      medicamentosList: [],
      isDisabledInputMedicamentoClinic: false,
      isDisabledInputMedicamentoErp: false,
      isButtonDisabledStock: false,
    };
  },
  created() {
    this.getIndicacionesClinic();
    this.getMedicamentosClinic();
  },
  methods: {
    async showModalEvent() {
      this.salidaProducto.producto = null;
      this.reset();
      await this.getListProduct(null);
      this.showModal = true;
      this.$emit('activateTheButton', 'productos');
    },
    onCloseModal() {
      this.showModal = false;
    },
    async getListProduct(query) {
      this.filter = query;
      this.temporaPageComboProducto += 1;
      const params = {
        page: 1,
        perPage: 100 * this.temporaPageComboProducto,
        filterKey: this.filter,
      };
      await axios.get('/configuration/tipoProducto/combo', { params })
        .then((response) => {
          const { data } = response.data;
          this.productosStatic = data.data;
          this.totalRegistro = data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    canAgregarProducto() {
      if (this.entryData !== null) {
        return (this.entryData.agrega_producto === 1);
      }
      return false;
    },
    validateTagMedicamentosBeforeSaving(tags) {
      if (tags.length !== 0) {
        const item = [...tags].pop();
        const longitud = this.medicamentosList.filter((obj) => obj.text === item.text).length;
        if (longitud !== 0) {
          this.tagsMedicamentos = tags;
        } else {
          this.tagsMedicamentos.push({ id: 0, text: item.text });
        }
        this.isDisabledInputMedicamentoErp = true;
        this.salidaProducto.producto = null;
      } else {
        this.tagsMedicamentos = [];
        this.isDisabledInputMedicamentoErp = false;
      }
    },
    saveProductos(tipo) {
      if (this.validate()) {
        this.agregarDetalle(tipo);
        this.salidaProducto.producto = null;
        this.reset();
        this.$emit('addProducto', this.detalles);
      }
    },
    customLabelProduct({ codigo, nombre, subGrupo }) {
      return `${codigo} - ${nombre} - ${subGrupo}`;
    },
    busquedaAsincronaProductos(query) {
      this.temporaPageComboProducto = 0;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.getListProduct(query), DELAY);
    },
    getDataProducto(producto) {
      // this.load_multiselect_product = true;
      if (!this.almacen) {
        util.showNotify('Debe seleccionar un almacen', 'warn');
        return;
      }
      const data = {
        almacen_id: this.almacen.id,
        producto_id: producto.id,
        fecha: moment(this.fecha).format('DD/MM/YYYY'),
        moneda_id: this.moneda.id,
      };
      // /inventario/traspaso_producto/producto/stock_lotes
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      axios.get('/inventario/traspaso_producto/producto/stock_lotes', { params: data })
        .then((response) => {
          this.isButtonDisabledStock = false;
          const DATA2 = response.data.data;
          // console.log('DATA2', DATA2);
          this.salidaProducto.stock.saldo_up = Math.round(DATA2.stock.saldo_up);
          this.salidaProducto.stock.saldo_ua = Math.round(DATA2.stock.saldo_ua);
          this.salidaProducto.producto = DATA2.producto;
          this.$refs.unidad.checked = true;
          if (DATA2.stock_lotes.length > 0) {
            this.stockLotes = DATA2.stock_lotes;
          }
          this.actualizarStock();
        })
        .catch((error) => {
          console.error(error);
          this.isButtonDisabledStock = true;
          if (error.response) {
            const response = error.response.data;
            switch (response.error) {
              case 6: {
              // util.showNotify(response.message, 'warn');
                this.reset();
                break;
              }
              case 7: {
                util.showNotify(response.message, 'warn', response.data.title);
                this.salidaProducto.producto = null;
                this.reset();
                break;
              }
              default: {
                console.log('error', response.error);
                const message = (response.error) ? response.message : 'Error, hubo problemas con el servidor, contactese con el Administrador.';
                util.showNotify(message, 'error');
              }
            }
          } else if (error.request) {
            util.showNotify('La solicitud se realizó pero no se recibió respuesta del servidor.', 'error');
          } else {
            util.showNotify('Algo sucedio al solicitar la solicitud al servidor.', 'error');
          }
        });
    },
    agregarTextoAGlosa(item) {
      if (this.salidaProducto.glosa) {
        const texto = this.salidaProducto.glosa;
        this.salidaProducto.glosa = `${texto} , ${item.nombre}`;
      } else {
        this.salidaProducto.glosa = item.nombre;
      }
    },
    validateTagIndicationBeforeSaving(tags) {
      if (tags.length !== 0) {
        const item = [...tags].pop();
        const longitud = this.indicacionesList.filter((obj) => obj.text === item.text).length;
        if (longitud !== 0) {
          this.tagsIndicacion = tags;
        } else {
          this.tagsIndicacion.push({ id: 0, text: item.text });
        }
      } else {
        this.tagsIndicacion = [];
      }
    },
    validate() {
      let validate = true;
      if (this.canAgregarProducto === false) {
        if (this.salidaProducto.stock.saldo_up < this.salidaProducto.cantidad) {
          util.showNotify('La cantidad requerida no es disponible en stock!', 'warn');
          validate = false;
        }
      }

      if (this.stockLotes.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        let cantidad = this.salidaProducto.cantidad;
        this.salidaProducto.stockLotes = this.stockLotes;
        for (let index = 0; index < this.stockLotes.length; index + 1) {
          const ele = this.stockLotes[index];
          cantidad -= ele.disponibles;
          // this.salidaProducto.stockLotes.push(ele);
          if (cantidad <= 0) {
            break;
          }
        }
        if (cantidad > 0) {
          util.showNotify('La cantidad requerida no es disponible en stock por Lotes!', 'warn');
          validate = false;
        }
      }
      return validate;
    },
    reset() {
      this.salidaProducto.stock = { saldo_ua: 0, saldo_up: 0 };
      this.salidaProducto.stockLotes = [];
      this.salidaProducto.glosa = '';
      this.salidaProducto.unidad = false;
      this.salidaProducto.cantidad = 1;
      // this.salidaProducto.producto = null;
      this.isDisabledInputMedicamentoClinic = false;
      this.isDisabledInputMedicamentoErp = false;
      this.tipoViaAdministracionSelected = null;
      this.tagsMedicamentos = [];
      this.tagsIndicacion = [];
      if (this.$refs.unidad) {
        this.$refs.unidad.checked = false;
      }
    },
    onlyNumber(event) {
      const tecla = (document.all) ? event.keyCode : event.which;
      if (tecla < 13) {
        return true;
      }
      if (tecla === 13) {
        return event.preventDefault();
      }
      return this.isNumber(String.fromCharCode(tecla)) ? true : event.preventDefault();
    },
    isNumber(cantidad) {
      const expresion = /^\d+$/;
      return expresion.test(cantidad);
    },
    formatDataMedicamento(data) {
      const listaTemp = data.map((item) => ({
        id: item.id,
        text: item.nombre,
      }));
      return listaTemp;
    },
    formatDataIndicacion(data) {
      const listaTemp = data.map((item) => ({
        id: item.id,
        text: item.nombre,
      }));
      return listaTemp;
    },
    async getMedicamentosClinic() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/medicamento/list');
        this.medicamentosList = this.formatDataMedicamento(response.data.data);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getIndicacionesClinic() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/indicacion_medicamento/list');
        this.indicacionesList = this.formatDataIndicacion(response.data.data);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    agregarDetalle(tipo) {
      if (this.salidaProducto.producto === null && this.tagsMedicamentos.length === 0) {
        util.showNotify('porfavor añada un Producto o Medicamento', 'warn');
        return;
      }
      // tipo = 1 insumo, tipo = 2 ventas
      const productoObj = this.salidaProducto;
      let detalle = {};
      const indicacion = this.tagsIndicacion[0];
      let medicamentoNombre = '';

      if (this.isDisabledInputMedicamentoErp) {
        if (this.tagsMedicamentos.length === 0) {
          util.showNotify('Seleccione un medicamento', 'warn');
          return;
        }
        const medicamento = this.tagsMedicamentos[0];
        medicamentoNombre = medicamento.text;
      }

      // Construir el objeto detalle
      detalle = {
        producto_numero: productoObj.producto?.numero || null,
        codigo: productoObj.producto?.codigo || null,
        producto_id: productoObj.producto?.id || null,
        producto_nombre: productoObj.producto?.nombre || medicamentoNombre,
        moneda_id: productoObj.producto?.moneda_id || null,
        unidad_medida_id: productoObj.producto?.unidad_medida?.id || null,
        unidad_medida_descripcion: productoObj.producto?.unidad_medida?.descripcion || null,
        descripcion: productoObj.producto?.descripcion || null,
        glosa: productoObj.glosa,
        cantidad: productoObj.cantidad,
        fecha: new Date(),
        fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
        usuario: this.user.name,
        tipo_consumo: tipo,
        estado: true,
        consumo_producto_id: 0,
        key: `CP- ${new Date().getTime()}`,
      };

      // Condicionalmente añadir propiedades relacionadas con la indicación
      if (indicacion) {
        detalle.indicacion_medicamento_id = indicacion.id;
        detalle.indicacion = indicacion.text;
        detalle.via = this.tipoViaAdministracionSelected?.nombre || null;
      } else {
        detalle.indicacion_medicamento_id = null;
        detalle.indicacion = null;
        detalle.via = null;
      }

      // Validación de precios
      if (productoObj.producto && (tipo !== 3 || productoObj.producto.precios)) {
        if (!productoObj.producto.precios || !productoObj.producto.precios[0]) {
          detalle.producto_precio = 0;
        } else {
          detalle.producto_precio = parseFloat(productoObj.producto.precios[0].monto);
        }
      } else {
        detalle.producto_precio = 0;
      }
      this.addDetalle(productoObj.stockLotes, detalle);
    },
    addDetalle(stockLotes, detalle) {
      if (!stockLotes || stockLotes.length === 0) {
        // Si no hay stockLotes, crear un detalle con valores en null
        const newDetalle = this.cloneObject(detalle);
        newDetalle.tc = null;
        newDetalle.lote_id = null;
        newDetalle.lote_numero = null;
        newDetalle.costo_unitario = null;
        newDetalle.total = 0;
        this.detalles.push(newDetalle);
      } else {
        // Si hay stockLotes, iterar y agregar detalles con valores correspondientes
        let cantidad = parseInt(detalle.cantidad, 10);
        for (let index = 0; index < stockLotes.length;) {
          const newDetalle = this.cloneObject(detalle);
          const element = stockLotes[index];
          const diferencia = Math.round(element.disponibles) - cantidad;
          cantidad = (diferencia > 0) ? cantidad : cantidad - Math.round(element.disponibles);
          newDetalle.cantidad = (diferencia > 0) ? cantidad : Math.round(element.disponibles);
          newDetalle.tc = element.tc;
          newDetalle.lote_id = element.lote_id;
          newDetalle.lote_numero = element.lote_numero;
          newDetalle.costo_unitario = parseFloat(element.costo_unitario);
          newDetalle.total = newDetalle.cantidad * newDetalle.costo_unitario;
          if (newDetalle.total === null) {
            newDetalle.total = 0;
          }
          newDetalle.tipo_consumo = detalle.tipo_consumo;
          this.detalles.push(newDetalle);
          index += 1;
          if (diferencia >= 0) {
            break;
          }
        }
      }
    },
    cloneObject(object) {
      const objectString = JSON.stringify(object);
      return JSON.parse(objectString);
    },
    actualizarStock() {
      let stockUtilizado = 0;
      this.detalles.forEach((ele) => {
        if (ele.producto_id === this.salidaProducto.producto.id) {
          stockUtilizado += ele.cantidad;
        }
      });
      this.salidaProducto.stock.saldo_up -= stockUtilizado;

      for (let index = 0; index < this.stockLotes.length;) {
        const ele = this.stockLotes[index];
        index += 1;
        if (stockUtilizado >= Math.round(ele.disponibles)) {
          stockUtilizado -= Math.round(ele.disponibles);
          ele.disponibles = 0;
        } else {
          ele.disponibles -= stockUtilizado;
          stockUtilizado = 0;
        }
        if (stockUtilizado === 0) {
          break;
        }
      }
    },
    delDetalle(index) {
      this.detalles.splice(index, 1);
    },
  },
  computed: {
    ...mapState('ConsultaExternaEdit', [
      'consulta',
    ]),
    ...mapFields('ConsultaExternaEdit', [
      'productos',
      'temporaPageComboProducto',
      'unidad',
      'tipoViaAdministracions',
      'tipoViaAdministracionSelected',
    ]),
    filteredItemsIndicaciones() {
      return this.indicacionesList.filter((i) => (
        i.text && this.indicacionModel
          && i.text.toLowerCase().indexOf(this.indicacionModel.toLowerCase()) !== -1));
    },
    filteredItemsMedicamentos() {
      return this.medicamentosList.filter((i) => (
        i.text.toLowerCase().indexOf(this.medicamentoModel.toLowerCase()) !== -1));
    },
    visualizaStock() {
      if (this.entryData !== null) {
        return (this.entryData.visualiza_stock === 1);
      }
      return false;
    },
    isButtonDisabled() {
      return this.isDisabledInputMedicamentoErp;
    },
    showCargarMas() {
      const isOkToSearch = this.temporaPageComboProducto * 100 < this.totalRegistro;
      return this.totalRegistro > 100 && isOkToSearch;
    },
    ...mapState('main', [
      'user',
    ]),
  },
  watch: {
  },
  components: {
    Multiselect2,
  },
};
</script>

<style scoped>
.btnBlue {
  color: white;
  background-color: #47404f;
}
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
</style>
