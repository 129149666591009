import { render, staticRenderFns } from "./TheModalConsumoProductos.vue?vue&type=template&id=62cb7a01&scoped=true&"
import script from "./TheModalConsumoProductos.vue?vue&type=script&lang=js&"
export * from "./TheModalConsumoProductos.vue?vue&type=script&lang=js&"
import style0 from "./TheModalConsumoProductos.vue?vue&type=style&index=0&id=62cb7a01&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62cb7a01",
  null
  
)

export default component.exports